import * as Sentry from '@sentry/sveltekit';
import { supertokensInit } from '$utils/auth/supertokens';
import { dev } from '$app/environment';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
  dsn: 'https://28d56348d5dc2b3dde709a98ab8667d4@o4507624523825152.ingest.us.sentry.io/4507624525725696',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  integrations: [Sentry.replayIntegration()],
  environment: dev ? 'development' : 'production',
});

supertokensInit();
export const handleError = Sentry.handleErrorWithSentry();
